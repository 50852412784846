import client from '../../../sanity-client-config';

const getEventById = (eventId) => {
    const query = `*[_type == "matchEvent" && _id == "${eventId}"]{
        club -> {
            isHammarby,
            title
        },
        "id": _id,
        minute,
        match -> {slug},
        player_substitute -> {
            firstName,
            lastName,
            number
        },
        player_reference -> {
            firstName,
            lastName,
            number
        },
        comment,
        type -> {
            name
        }
    }`;
    return client.fetch(query).then(res => res);
};

export default getEventById;
