// extracted by mini-css-extract-plugin
export var matchLineUp = "matchLineUp-module--matchLineUp--IPziv";
export var matchLineUp__coachLastName = "matchLineUp-module--matchLineUp__coachLastName--vMo5t";
export var matchLineUp__composition = "matchLineUp-module--matchLineUp__composition--ZMRR1";
export var matchLineUp__localTeamContent = "matchLineUp-module--matchLineUp__localTeamContent--PPWvc";
export var matchLineUp__minute = "matchLineUp-module--matchLineUp__minute--8SK8H";
export var matchLineUp__player = "matchLineUp-module--matchLineUp__player--pzoOx";
export var matchLineUp__playerAvatar = "matchLineUp-module--matchLineUp__playerAvatar--Dyw+M";
export var matchLineUp__playerAvatarContainer = "matchLineUp-module--matchLineUp__playerAvatarContainer--yjUA6";
export var matchLineUp__playerAvatarImage = "matchLineUp-module--matchLineUp__playerAvatarImage--xyX5V";
export var matchLineUp__playerCard = "matchLineUp-module--matchLineUp__playerCard--qPM6N";
export var matchLineUp__playerInfo = "matchLineUp-module--matchLineUp__playerInfo--+-Rk6";
export var matchLineUp__playerItem = "matchLineUp-module--matchLineUp__playerItem--7GOgq";
export var matchLineUp__playerLastName = "matchLineUp-module--matchLineUp__playerLastName--PiURz";
export var matchLineUp__playerNumber = "matchLineUp-module--matchLineUp__playerNumber--BNPEp";
export var matchLineUp__playerPlaceholder = "matchLineUp-module--matchLineUp__playerPlaceholder--RiHN6";
export var matchLineUp__playerSubstitution = "matchLineUp-module--matchLineUp__playerSubstitution--Y00T3";
export var matchLineUp__playerSwitch = "matchLineUp-module--matchLineUp__playerSwitch--IVCPC";
export var matchLineUp__playersList = "matchLineUp-module--matchLineUp__playersList--jCj-M";
export var matchLineUp__playersListExternal = "matchLineUp-module--matchLineUp__playersListExternal--ffhPv";
export var matchLineUp__playersListLocal = "matchLineUp-module--matchLineUp__playersListLocal--WwcXw";
export var matchLineUp__separator = "matchLineUp-module--matchLineUp__separator--CqZQe";
export var matchLineUp__separatorLine = "matchLineUp-module--matchLineUp__separatorLine--swI6r";
export var matchLineUp__separatorText = "matchLineUp-module--matchLineUp__separatorText--7LG+V";
export var matchLineUp__team = "matchLineUp-module--matchLineUp__team--LIiw6";
export var matchLineUp__teamContent = "matchLineUp-module--matchLineUp__teamContent--N8ABa";
export var matchLineUp__teamLocal = "matchLineUp-module--matchLineUp__teamLocal--+DG8-";
export var matchLineUp__teamLogo = "matchLineUp-module--matchLineUp__teamLogo--5wuBy";
export var matchLineUp__teamTitle = "matchLineUp-module--matchLineUp__teamTitle--e5cMB";
export var matchLineUp__teams = "matchLineUp-module--matchLineUp__teams--LB1NV";