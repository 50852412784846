import React from 'react';

import * as style from 'styles/components/match/page/liveStream.module.scss';
import { useAuth } from 'context/UserContext';

const LiveStream = ({ match }) => {
    const { isSignedIn } = useAuth();
    return (
        <div>
        {isSignedIn ? (
            <div>
                <div className={style.liveStream__container}>
                    <iframe title="LiveStream" src={match.liveStream.url} frameBorder='0' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen/>
                </div>
            </div>
        ) : (
            <div>
                <div className={style.liveStream__container}>
                    <iframe title="LiveStream" src={match.liveStream.url} frameBorder='0' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </div>
            </div>
        )}
        </div>
    );
};
export default LiveStream;
