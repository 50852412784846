import React from 'react';

import FormattedMessage from 'components/utils/FormattedMessage';
import * as style from 'styles/components/news/blockList.module.scss';

import CustomLink from '../utils/CustomLink';

import NewsItem from './Item';

const NewsBlockList = ({ title, items }) => (
    <div className={`${style.newsBlockList} wrapper block-paddings`}>
        <h2 className="title-sm title-xs-mobile text-semibold">{title}</h2>
        <ul>
            {items.length ? (
                items.map((item, index) => (
                    <li key={index}>
                        <NewsItem item={item} />
                    </li>
                ))
            ) : (
                <FormattedMessage id="news.noNews" />
            )}
        </ul>
        <div className="center">
            <CustomLink className="cta-white-sec" namedRoute="newsList">
                <FormattedMessage id="news.seeMoreNews" />
            </CustomLink>
        </div>
    </div>
);

export default NewsBlockList;
