import * as React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

const Image = ({ image, width, height, className }) => {
    if (!image.image) {
        return null;
    }
    return (
        <ImageGatsby
            {...image.image}
            width={width}
            height={height}
            alt={image.alt || ''}
            className={className}
        />
    );
};

export default Image;
