import React from 'react';
import { Script } from "gatsby";
import { Helmet } from 'react-helmet';

const MatchAnalysis = ({ match }) => {
    let teamId = 6710;
    let competitionId = 808;

    if(match.team._id === 'team-253894' || match.teamExternal._id === 'team-253894') {
        teamId = 6803;
        competitionId = 809;
    } else if( match.team._id === 'team-2' || match.teamExternal._id === 'team-2') {
        teamId = 6710;
        competitionId = 808;
    }
    return (
        <>
            <Helmet>
            <script type="text/javascript">
            {`window.TWELVE_WIDGET_API_SECRET = '6b8d6bf1-423c-423c-89fd-874d7cc3b2aa'; console.log(window.TWELVE_WIDGET_API_SECRET );`}
            </script>
            </Helmet>
            <Script src="https://twelve.blob.core.windows.net/twelve-widgets/twelve-match-report-widget.js" />
            <twelve-match-report-widget provider="wyscout" match-id={match.wyscoutId} competition-id={competitionId} team-id={teamId} />
        </>
    );
};

export default MatchAnalysis;
