// extracted by mini-css-extract-plugin
export var matchHeader = "header-module--matchHeader--YsRVo";
export var matchHeaderCounter = "header-module--matchHeaderCounter--SfQua";
export var matchHeaderCounter__colon = "header-module--matchHeaderCounter__colon--4FbSn";
export var matchHeaderCounter__completed = "header-module--matchHeaderCounter__completed--5G7-2";
export var matchHeaderCounter__info = "header-module--matchHeaderCounter__info--1jOCz";
export var matchHeaderCounter__item = "header-module--matchHeaderCounter__item--IGE9Q";
export var matchHeaderCounter__number = "header-module--matchHeaderCounter__number--WDgwR";
export var matchHeaderCounter__wrapper = "header-module--matchHeaderCounter__wrapper--Zqnzn";
export var matchHeaderImage = "header-module--matchHeaderImage--QtLo4";
export var matchHeader__actions = "header-module--matchHeader__actions--fJG6V";
export var matchHeader__actionsIcons = "header-module--matchHeader__actionsIcons--740nW";
export var matchHeader__arrowLeft = "header-module--matchHeader__arrowLeft--JI5vy";
export var matchHeader__arrowRight = "header-module--matchHeader__arrowRight--Bnl2F";
export var matchHeader__arrowsComponent = "header-module--matchHeader__arrowsComponent--0P7CG";
export var matchHeader__ballIcon = "header-module--matchHeader__ballIcon--AGT5U";
export var matchHeader__centralPart = "header-module--matchHeader__centralPart--YXZIS";
export var matchHeader__competition = "header-module--matchHeader__competition--AEg97";
export var matchHeader__content = "header-module--matchHeader__content--jj0HT";
export var matchHeader__extension = "header-module--matchHeader__extension--KnGj3";
export var matchHeader__extensionLine = "header-module--matchHeader__extensionLine--p+ZVH";
export var matchHeader__externalAction = "header-module--matchHeader__externalAction--AonkR";
export var matchHeader__externalActions = "header-module--matchHeader__externalActions--QzAKN";
export var matchHeader__externalEventMinute = "header-module--matchHeader__externalEventMinute---Gm1H";
export var matchHeader__externalTeam = "header-module--matchHeader__externalTeam--4MbCT";
export var matchHeader__firstPeriod = "header-module--matchHeader__firstPeriod--HwpJP";
export var matchHeader__firstPeriodLine = "header-module--matchHeader__firstPeriodLine--L8UDU";
export var matchHeader__greenArrow = "header-module--matchHeader__greenArrow--lCtAK";
export var matchHeader__localAction = "header-module--matchHeader__localAction--s4pyB";
export var matchHeader__localActionName = "header-module--matchHeader__localActionName--j9QyM";
export var matchHeader__localActions = "header-module--matchHeader__localActions--JhwqK";
export var matchHeader__localEventMinute = "header-module--matchHeader__localEventMinute--RCIvq";
export var matchHeader__localTeam = "header-module--matchHeader__localTeam--YNAft";
export var matchHeader__location = "header-module--matchHeader__location--F63iL";
export var matchHeader__logo = "header-module--matchHeader__logo--ycXff";
export var matchHeader__logos = "header-module--matchHeader__logos---oLyT";
export var matchHeader__minute = "header-module--matchHeader__minute--tJurc";
export var matchHeader__playerLastName = "header-module--matchHeader__playerLastName--8alNN";
export var matchHeader__redArrow = "header-module--matchHeader__redArrow--M9zmP";
export var matchHeader__redCard = "header-module--matchHeader__redCard--oZs+s";
export var matchHeader__score = "header-module--matchHeader__score--Oen9d";
export var matchHeader__scoreHammarby = "header-module--matchHeader__scoreHammarby--fBpgA";
export var matchHeader__scoreOpponent = "header-module--matchHeader__scoreOpponent---7ULL";
export var matchHeader__secondPeriod = "header-module--matchHeader__secondPeriod--n3c-W";
export var matchHeader__secondPeriodLine = "header-module--matchHeader__secondPeriodLine--XcRgU";
export var matchHeader__sponsors = "header-module--matchHeader__sponsors--3ZaGp";
export var matchHeader__sponsorsHeading = "header-module--matchHeader__sponsorsHeading--E82WO";
export var matchHeader__startEventDate = "header-module--matchHeader__startEventDate--Dkb2Y";
export var matchHeader__teams = "header-module--matchHeader__teams--Zwjga";
export var matchHeader__teamsLogo = "header-module--matchHeader__teamsLogo--63Sv7";
export var matchHeader__teamsLogos = "header-module--matchHeader__teamsLogos--NKL2q";
export var matchHeader__timeline = "header-module--matchHeader__timeline--Tbqz6";
export var matchHeader__yellowCard = "header-module--matchHeader__yellowCard--l1M4e";