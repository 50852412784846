import React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import FormattedMessage from 'components/utils/FormattedMessage';
import * as style from 'styles/components/match/page/stats.module.scss';

const MatchStats = ({ match: { local, external, localStatistic, externalStatistic } }) => {
    if (!localStatistic && externalStatistic) {
        return null;
    }
    return (
        <div className={style.matchStats}>
            <h2 className="title-xs text-bold center">
                <FormattedMessage id="match.statistics" />
            </h2>
            <div className={style.matchStats__logos}>
                {local?.image && (
                    <div>
                        <ImageGatsby
                            {...local.image}
                            alt={local.title}
                            title={local.title}
                            width={35}
                        />
                    </div>
                )}
                {external?.image && (
                    <div>
                        <ImageGatsby
                            {...external.image}
                            alt={external.title}
                            title={external.title}
                            width={35}
                        />
                    </div>
                )}
            </div>
            <div className={style.matchStats__content}>
                {localStatistic &&
                    externalStatistic &&
                    [
                        'score',
                        'possession',
                        'shots',
                        'shotsOnGoal',
                        'corners',
                        'offsides',
                        'yellowCard',
                        'redCard',
                    ].map(
                        (statName) =>
                            Object.prototype.hasOwnProperty.call(localStatistic, statName) &&
                            Object.prototype.hasOwnProperty.call(externalStatistic, statName) && (
                                <div key={statName}>
                                    <div className={style.matchStats__texts}>
                                        <span className="text-sm text-semibold">
                                            {localStatistic[statName]}
                                        </span>
                                        <span className="text-xs text-darker uppercase">
                                            {/* TODO dynamize translation once translation tool is implemented */}
                                            <FormattedMessage id={`match.${statName}`} />

                                        </span>
                                        <span className="text-sm text-semibold">
                                            {externalStatistic[statName]}
                                        </span>
                                    </div>
                                    <div className={style.matchStats__bars_container}>
                                        <div>
                                            <span
                                                style={{
                                                    width: `${
                                                        (localStatistic[statName] /
                                                            (localStatistic[statName] +
                                                                externalStatistic[statName])) *
                                                        100
                                                    }%`,
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span
                                                style={{
                                                    width: `${
                                                        (externalStatistic[statName] /
                                                            (localStatistic[statName] +
                                                                externalStatistic[statName])) *
                                                        100
                                                    }%`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ),
                    )}
                <p className={`${style.matchStats__src} text-xs text-darker`}>
                    {/* TODO dynamize translation & field once implemented */}
                </p>
            </div>
        </div>
    );
};

export default MatchStats;
