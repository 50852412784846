// extracted by mini-css-extract-plugin
export var match = "page-module--match--vnsYf";
export var match__aside = "page-module--match__aside--0ihYI";
export var match__content = "page-module--match__content---nV7j";
export var match__hideDesktop = "page-module--match__hideDesktop--jEZqA";
export var match__hideMobile = "page-module--match__hideMobile--b3i7W";
export var match__image = "page-module--match__image--qIpz3";
export var match__imageGallery = "page-module--match__imageGallery---TyTp";
export var match__imageGalleryWrapper = "page-module--match__imageGalleryWrapper--8RlFp";
export var match__main = "page-module--match__main--mE2jp";
export var match__matchLiveContainer = "page-module--match__matchLiveContainer--KEN0z";