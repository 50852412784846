import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import * as style from 'styles/components/match/page/partner.module.scss';

const MatchPartner = ({ match }) => {
    const { lang } = useContext(LangContext);
    const isASponsor = match.primarySponsor || match.secondarySponsor;

    return (
        <div className={style.partner__container}>
            {match.primarySponsor && (
                <a
                    href={match.primarySponsor.url}
                    className={style.partner__wrapper}
                    target="_blank"
                    rel="noreferrer"
                >
                    <p className="text-sm medium text-center">
                        <FormattedMessage id="match.partnerSponsoredBy" />
                    </p>
                    <ImageGatsby
                        {...match.primarySponsor.logoWhite}
                        alt={match.primarySponsor.title}
                        title={match.primarySponsor.title}
                    />
                </a>
            )}

            {match.secondarySponsor && (
                <a
                    href={match.secondarySponsor.url}
                    className={style.partner__wrapper}
                    target="_blank"
                    rel="noreferrer"
                >
                    <p className="text-sm medium text-center">
                        <FormattedMessage id="match.partnerSponsoredBy" />
                    </p>
                    <ImageGatsby
                        {...match.secondarySponsor.logoWhite}
                        alt={match.secondarySponsor.title}
                        title={match.secondarySponsor.title}
                    />
                </a>
            )}

            {/* todo get the link of become a partner - use Link component if it's an internal link */}
            {isASponsor && (
                <a href="/" className={`${style.partner__link} text-md semibold`} aria-label={getFormattedMessage('match.partnerLink', lang)}>
                    <FormattedMessage id="match.partnerLink" />
                    <i className="ico-arrow" aria-hidden />
                </a>
            )}
        </div>
    );
};

export default MatchPartner;
