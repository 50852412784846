// extracted by mini-css-extract-plugin
export var matchLive = "matchLive-module--matchLive--0BHws";
export var matchLive__arrowLeft = "matchLive-module--matchLive__arrowLeft--js63Z";
export var matchLive__arrowRight = "matchLive-module--matchLive__arrowRight--Y+1Rm";
export var matchLive__arrowsComponent = "matchLive-module--matchLive__arrowsComponent--rV74G";
export var matchLive__ballIcon = "matchLive-module--matchLive__ballIcon--8dvU+";
export var matchLive__commentDescription = "matchLive-module--matchLive__commentDescription--nZJ-6";
export var matchLive__commentIcon = "matchLive-module--matchLive__commentIcon--nGxVr";
export var matchLive__commentSection = "matchLive-module--matchLive__commentSection--aNEx1";
export var matchLive__commentTitle = "matchLive-module--matchLive__commentTitle--7I4-3";
export var matchLive__footer = "matchLive-module--matchLive__footer--p+DWh";
export var matchLive__goalExternal = "matchLive-module--matchLive__goalExternal--5JAu2";
export var matchLive__goalLocal = "matchLive-module--matchLive__goalLocal--sOVVv";
export var matchLive__greenArrow = "matchLive-module--matchLive__greenArrow--RfDRI";
export var matchLive__header = "matchLive-module--matchLive__header--dPXh3";
export var matchLive__image = "matchLive-module--matchLive__image--cjMmE";
export var matchLive__imageOverlay = "matchLive-module--matchLive__imageOverlay--M1PZ4";
export var matchLive__imagesContainer = "matchLive-module--matchLive__imagesContainer--j+y-F";
export var matchLive__redArrow = "matchLive-module--matchLive__redArrow--w-juI";
export var matchLive__redCard = "matchLive-module--matchLive__redCard--olB2T";
export var matchLive__source = "matchLive-module--matchLive__source--QJ0uX";
export var matchLive__timelineItem = "matchLive-module--matchLive__timelineItem--hGX1D";
export var matchLive__videoCover = "matchLive-module--matchLive__videoCover--x7qDq";
export var matchLive__yellowCard = "matchLive-module--matchLive__yellowCard--66R6I";