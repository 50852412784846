import client from "../../../sanity-client-config";

const getAllMatchEventsById = (matchId) => {
    const query = `*[_type == "matchEvent" && !(_id in path("drafts.**")) && match -> slug.current == "${matchId}"]  | order(minute desc) {
        club -> {
            isHammarby,
            title
        },
        "id": _id,
        minute,
        match -> {slug},
        player_substitute -> {
            firstName,
            lastName,
            number
        },
        player_reference -> {
            firstName,
            lastName,
            number
        },
        comment,
        type -> {
            name
        }
    }`;
    return client.fetch(query).then(res => res);
}

export default getAllMatchEventsById;
