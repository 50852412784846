import React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';
import { StaticImage } from 'gatsby-plugin-image';

import * as style from '../../../styles/components/match/page/matchLineUp.module.scss';
import * as eventsStyle from '../../../styles/components/match/page/header.module.scss';

const PlayerAvatar = ({ player, isMatchStarted, matchEvents }) => {
    const getPlayerEvents = () =>
        // eslint-disable-next-line array-callback-return,consistent-return
        matchEvents?.map((event) => {
            if (
                event.type.name === 'yellow-card' &&
                event.player_reference &&
                event.player_reference.lastName === player.lastName
            ) {
                return (
                    <div
                        className={`${style.matchLineUp__playerCard} ${eventsStyle.matchHeader__yellowCard}`}
                    />
                );
            }

            if (
                event.type.name === 'red-card' &&
                event.player_reference &&
                event.player_reference.lastName === player.lastName
            ) {
                return (
                    <div
                        className={`${style.matchLineUp__playerCard} ${eventsStyle.matchHeader__redCard}`}
                    />
                );
            }

            if (
                event.type.name === 'switch-players' &&
                event.player_reference &&
                event.player_reference.lastName === player.lastName
            ) {
                return (
                    <div className={style.matchLineUp__playerSwitch}>
                        <div className={style.matchLineUp__minute}>{`${event.minute}'`}</div>
                        <div className={eventsStyle.matchHeader__arrowsComponent}>
                            <i
                                className={`ico-arrow ${eventsStyle.matchHeader__arrowRight}`}
                                aria-hidden="true"
                            />
                            <i
                                className={`ico-arrow ${eventsStyle.matchHeader__arrowLeft} ${eventsStyle.matchHeader__redArrow}`}
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                );
            }

            if (
                event.type.name === 'switch-players' &&
                event.player_reference &&
                event.player_substitute.lastName === player.lastName
            ) {
                return (
                    <div className={style.matchLineUp__playerSwitch}>
                        <div className={style.matchLineUp__minute}>{`${event.minute}'`}</div>
                        <div className={eventsStyle.matchHeader__arrowsComponent}>
                            <i
                                className={`ico-arrow ${eventsStyle.matchHeader__arrowRight} ${eventsStyle.matchHeader__greenArrow}`}
                                aria-hidden="true"
                            />
                            <i
                                className={`ico-arrow ${eventsStyle.matchHeader__arrowLeft}`}
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                );
            }
        });

    return (
        <div className={style.matchLineUp__playerAvatar}>
            <div className={style.matchLineUp__playerAvatarContainer}>
                {player.image && player.image[0] ? (
                    <ImageGatsby
                        {...player.image[0].image}
                        alt={`${player.firstName} ${player.lastName}`}
                        className={style.matchLineUp__playerAvatarImage}
                    />
                ) : (
                    <StaticImage
                        src="../../../images/playerPlaceholder.png"
                        alt="Player image"
                        className={style.matchLineUp__playerPlaceholder}
                    />
                )}
                {isMatchStarted && getPlayerEvents()}
            </div>
        </div>
    );
};

export default PlayerAvatar;
