import * as React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/match/page/matchLineUp.module.scss';
import { MATCH_STATUS } from "data/constants";
import FormattedMessage from 'components/utils/FormattedMessage';
import PlayerAvatar from 'components/match/PageParts/PlayerAvatar';

const MatchLineUpPlayers = ({ match: { match, matchEvents } }) => {
    const isMatchStarted = match.matchStatus !== MATCH_STATUS.PLANNED && match.matchStatus !== MATCH_STATUS.CANCELLED;

    const localPlayers = match.localLineup.filter(player => player.position === 'player');
    const externalPlayers = match.externalLineup.filter(player => player.position === 'player');

    const localSubstitutes = match.localLineup.filter(player => player.position === 'substitute');
    const externalSubstitutes = match.externalLineup.filter(player => player.position === 'substitute');

    const localCoaches = match.localLineup.filter(player => player.position === 'coach');
    const externalCoaches = match.externalLineup.filter(player => player.position === 'coach');

    const renderPlayer = (player) => (
        <>
            <div className={style.matchLineUp__playerInfo}>
                <div className={style.matchLineUp__player}>
                    <div className={style.matchLineUp__playerLastName}>{player.lastName}</div>
                    <div className={style.matchLineUp__playerNumber}>{player.number}</div>
                </div>
                {/* eslint-disable-next-line array-callback-return,consistent-return */}
                {matchEvents.map((event, index) => {
                    if (event.player_substitute?.lastName === player.lastName) {
                        return (
                            <div key={index}
                                 className={`${style.matchLineUp__player} ${style.matchLineUp__playerSubstitution}`}
                            >
                                <div className={style.matchLineUp__playerLastName}>
                                    {event.player_reference?.lastName}
                                </div>
                                <div className={style.matchLineUp__playerNumber}>
                                    {event.player_reference?.number}
                                </div>
                            </div>
                        );
                    }

                    if (event.player_reference?.lastName === player.lastName) {
                        return (
                            <div key={index}
                                 className={`${style.matchLineUp__player} ${style.matchLineUp__playerSubstitution}`}
                            >
                                <div className={style.matchLineUp__playerLastName}>
                                    {event.player_substitute?.lastName}
                                </div>
                                <div className={style.matchLineUp__playerNumber}>
                                    {event.player_substitute?.number}
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
            <PlayerAvatar
                player={player}
                isMatchStarted={isMatchStarted}
                matchEvents={matchEvents}
            />
        </>
    );

    const renderPlayerList = (name, local, external) => (
        <>
            {name && (
                <div className={style.matchLineUp__separator}>
                    <div className={style.matchLineUp__separatorLine} />
                    <div className={style.matchLineUp__separatorText}>
                        <FormattedMessage id={name} />
                    </div>
                    <div className={style.matchLineUp__separatorLine} />
                </div>
            )}

            <div className={style.matchLineUp__playersList}>
                <div className={style.matchLineUp__playersListLocal}>
                    {local.map((item, index) => (
                        <div className={style.matchLineUp__playerItem} key={index}>
                            {renderPlayer(item.player)}
                        </div>
                    ))}
                </div>
                <div className={style.matchLineUp__playersListExternal}>
                    {external.map((item, index) => (
                        <div className={style.matchLineUp__playerItem} key={index}>
                            {renderPlayer(item.player)}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );

    return (
        <div className={style.matchLineUp}>
            <div className={style.matchLineUp__teams}>
                <div className={`${style.matchLineUp__team} ${style.matchLineUp__teamLocal}`}>
                    <div
                        className={`${style.matchLineUp__teamContent} ${style.matchLineUp__localTeamContent}`}
                    >
                        <div className={style.matchLineUp__teamTitle}>{match.local.title}</div>
                        <div className={style.matchLineUp__composition}>
                            {match.localLineupComposition}
                        </div>
                    </div>
                    {match.local.image?.asset && (
                        <ImageGatsby
                            {...match.local.image}
                            alt={match.local.title}
                            className={style.matchLineUp__teamLogo}
                        />
                    )}
                </div>
                <div className={style.matchLineUp__team}>
                    {match.external.image?.asset && (
                        <ImageGatsby
                            {...match.external.image}
                            alt={match.external.title}
                            className={style.matchLineUp__teamLogo}
                        />
                    )}
                    <div className={style.matchLineUp__teamContent}>
                        <div className={style.matchLineUp__teamTitle}>{match.external.title}</div>
                        <div className={style.matchLineUp__composition}>
                            {match.externalLineupComposition}
                        </div>
                    </div>
                </div>
            </div>

            {(localPlayers.length !== 0 || externalPlayers.length !== 0) &&
                renderPlayerList(null, localPlayers, externalPlayers)
            }

            {(localSubstitutes.length !== 0 || externalSubstitutes.length !== 0) &&
                renderPlayerList('match.substitutes', localSubstitutes, externalSubstitutes)
            }

            {(localCoaches.length !== 0 || externalCoaches.length !== 0) &&
                renderPlayerList('match.coaches', localCoaches, externalCoaches)
            }
        </div>
    );
};

export default MatchLineUpPlayers;
