// extracted by mini-css-extract-plugin
export var splash = "splash-module--splash--gzM7D";
export var splash__anim = "splash-module--splash__anim--kQqFC";
export var splash__backgroundCover = "splash-module--splash__backgroundCover--gDWsH";
export var splash__backgrounds = "splash-module--splash__backgrounds--YY3-K";
export var splash__bg = "splash-module--splash__bg--NISYV";
export var splash__clubs = "splash-module--splash__clubs--D5n1A";
export var splash__clubsMultiple = "splash-module--splash__clubsMultiple--6BDtN";
export var splash__clubsMultiple__logo = "splash-module--splash__clubsMultiple__logo--32fyl";
export var splash__clubsMultiple__name = "splash-module--splash__clubsMultiple__name--SIsYh";
export var splash__clubsMultiple__names = "splash-module--splash__clubsMultiple__names--tZQYg";
export var splash__clubsMultiple__vs = "splash-module--splash__clubsMultiple__vs--wSdar";
export var splash__clubs__logo = "splash-module--splash__clubs__logo--KYiFW";
export var splash__clubs__name = "splash-module--splash__clubs__name--hZekV";
export var splash__clubs__names = "splash-module--splash__clubs__names--0LRJv";
export var splash__clubs__vs = "splash-module--splash__clubs__vs--lum7P";
export var splash__competitionInfo = "splash-module--splash__competitionInfo--czo3p";
export var splash__content = "splash-module--splash__content--nhCZF";
export var splash__contentMultiple = "splash-module--splash__contentMultiple--FxlRo";
export var splash__count = "splash-module--splash__count--EYYiL";
export var splash__countMultiple = "splash-module--splash__countMultiple--1VRNQ";
export var splash__countMultiple__colon = "splash-module--splash__countMultiple__colon--FarCj";
export var splash__countMultiple__completed = "splash-module--splash__countMultiple__completed--fscWX";
export var splash__countMultiple__info = "splash-module--splash__countMultiple__info--Sikc6";
export var splash__countMultiple__item = "splash-module--splash__countMultiple__item--ysgsi";
export var splash__countMultiple__number = "splash-module--splash__countMultiple__number--+5YIh";
export var splash__countMultiple__wrapper = "splash-module--splash__countMultiple__wrapper--3dlkw";
export var splash__count__colon = "splash-module--splash__count__colon--j3yDN";
export var splash__count__completed = "splash-module--splash__count__completed--wFB5e";
export var splash__count__info = "splash-module--splash__count__info--mL3JI";
export var splash__count__item = "splash-module--splash__count__item--qOCad";
export var splash__count__number = "splash-module--splash__count__number--W9NdX";
export var splash__count__wrapper = "splash-module--splash__count__wrapper--6kOxy";
export var splash__date = "splash-module--splash__date--oBjBk";
export var splash__dateMultiple = "splash-module--splash__dateMultiple--ml+pe";
export var splash__dateMultiple__top = "splash-module--splash__dateMultiple__top--GEOXN";
export var splash__date__top = "splash-module--splash__date__top--DRHuI";
export var splash__join = "splash-module--splash__join--hzi2U";
export var splash__match = "splash-module--splash__match--NDTf2";
export var splash__singleBackground = "splash-module--splash__singleBackground--7LuCn";
export var splash__sponsor = "splash-module--splash__sponsor--eJzPh";
export var splash__sponsorMultiple = "splash-module--splash__sponsorMultiple--quJnT";